import styled from 'styled-components'
import { TextLink } from 'components/common'
import { Radio } from 'linkdrop-ui'

export const StyledRadio = styled(Radio)`
  margin-bottom: 20px;
`

export const TextLinkStyled = styled(TextLink)`
  font-weight: 700;
`