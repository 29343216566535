import styled from "styled-components"
import {
  Select
} from 'linkdrop-ui'
import { AsidePopup } from 'components/common'

export const AsidePopupStyled = styled(AsidePopup)`
  display: grid;
  grid-template-rows: min-content min-content 1fr min-content;
  height: 100vh;
`

export const SelectStyled = styled(Select)`
  
`